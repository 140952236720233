.selling-point {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 120px 10%;
    gap: 30px;

    .sp-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        max-width: 500px;
        @media (min-width: breakpoints(md)) {
            width: 40%;
        }

        .title {
            text-align: center;
            margin-bottom: 20px;
            color: palette(secondary, dark);
        }
    }
    .sp-cards {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
        max-width: 700px;
        @media (min-width: breakpoints(md)) {
            width: 55%;
        }

        .sp-card {
            border-radius: shape(borderRadius);
            border: solid 1px palette(secondary, main);
            border-left: solid 15px palette(secondary, main);
            padding: 20px;
        }
        .sp-card-title {
            text-transform: uppercase;
            color: palette(secondary, dark);
            margin-bottom: 5px;
        }
    }
}