.request-form {
    background-color: #F0F2F5 !important;
    border: 1px solid #E5E5E5 !important;
    padding: 20px;
    max-width: 500px;
    justify-self: center;

    .title {
        align-content: center;
        text-align: center;
    }
    .form-fields {
        margin-bottom: 30px;

        .field {
            padding: 5px;
            
            .label {
                @include typography(body1);
                font-size: 12px !important;
            }
            .input {
                background-color: white !important;
                margin: 0;
    
                .MuiOutlinedInput-root {
                    height: 40px !important;
                }
    
                &.multi-line{
                    .MuiOutlinedInput-root {
                        height: 100% !important;
                    }
                }
                
                .MuiOutlinedInput-notchedOutline {
                    border: none !important;
                }
            }
            .error {
                @include typography(body1);
                font-size: 12px !important;       
                font-style: italic;
                color: red;     
            }
        }
    }
    .actions {
        @extend .center-content;

        .btn-submit {
            background-color: $primary-green;
        }
    }

}