.hero {
    min-height: 90vh;

    .top-hero {
        padding: 0px 120px;
        @media (max-width: breakpoints(md)) {
            padding: 100px 20px 20px 20px;
        }

        .title-section {
            @extend .flex-column-center;
    
            .craft-logo {
                margin-bottom: 60px;
            }    
            .tag-line {
                margin-bottom: 50px;
    
                .line-one {
                    color: palette(primary, main);
                    margin-bottom: 20px;
                }
                .line-two {
                    margin-bottom: 10px;
                }
                .line-three {
                    font-size: 18px;
                    color: palette(text, secondary);
                    margin-bottom: 10px;
                }
                .line-four {
                    @extend .line-three;
                    font-size: 16px;
                    margin-top: 10px;
                }
            }
        }
        .graphic-section {
            @extend .center-content;
        }
        .financial-svg {
            zoom: 2;
            max-width: 750px;
        }
    }
    .bottom-hero {
        @extend .flex-column-center;
        background-color: palette(background, inset);
        // box-shadow: inset 0px .5px 6px palette(grey,400);
        width: 100%;
        @media (max-width: breakpoints(md)) {
            padding: 40px;
        }
        min-height: 150px;

        .statement {
            text-align: center;
            // color: palette(primary, main);
            font-weight: 600 !important;

            &.first {
                margin-bottom: 10px;
            }
        }
    }
}