.how-it-works {
    height: max-content;
    padding: 100px 150px;
    width: 100%;

    @media (max-width: breakpoints(md)) {
        padding: 80px !important;
    }

    @media (max-width: breakpoints(sm)) {
        padding: 50px !important;
        
    }

    .title { 
        @extend .section-title;
        color: palette(primary,main);
    }
    .divider {
        @extend .divider;
        width: 180px !important;
        border-bottom-color: palette(primary,main) !important;
    }
    .content {
        height: 100%;
        .text-section {
            margin-top: 50px;
            .accordion {
                @extend .basic-accordion;
                
                .summary {
                    @extend .basic-accordion-summary;
                    background: $primary-blue;
                    background: linear-gradient(90deg, palette(primary,main) 0%, palette(primary,light) 100%);
                
                    .expand-icon {
                        color: palette(primary,contrastText);
                    }
                    .text {
                        @include typography(h6);
                        color: palette(primary,contrastText);
                        font-weight: bold !important;
                    }
                }
                .details {
                    @extend .basic-accordion-details;
                    
                    .text {
                        @include typography(body1);
                        color: palette(text,primary) !important;
                        font-weight: light !important;
                        line-height: 1.87 !important;
                    }
                }
            }
        }
        .img-section {
            display: flex;
            height: 100%;
            width: 100%;
            margin-top: 50px;
            padding: 0px 30px;

            @media (max-width: breakpoints(md)) {
                padding: 0px;
            }


            .img {
                width: 100%;
                height: auto;
            }
            .img-caption {
                @include typography(body1);
                font-weight: 600 !important;
                text-align: center;
                color: palette(text,primary) !important;
                padding-top: 20px;
            }
        }
    }
}