.key-features {
    width: 100%;
    padding: 80px 0px;
    background: linear-gradient(90deg, palette(secondary, main) 0%, $secondary-green 100%);
    box-shadow: inset 0px 1px 6px palette(secondary, dark);

    .kf-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            @extend .section-title;
            color: white;
        }
        .divider {
            @extend .divider;
            border-bottom-color: white;
            width: 50%;
            margin-bottom: 40px;
        }
    }
    .kf-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .kf-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            margin: 20px;
            padding: 20px;
            background-color: palette(background, inset);
            border-radius: shape(borderRadius);
        }
        .check-icon {
            font-size: 5rem;
            margin-bottom: 20px;
            color: darken($primary-green, 5%);
        }
        .kf-card-title {
            margin-bottom: 20px;
            text-align: center;
            text-transform: uppercase;
            height: 60px;
            display: flex;
            align-items: center;
            line-height: 1.25;
            color: darken($primary-green, 5%);
        }
        .kf-card-blurb {
            text-align: center;
            color: palette(text, secondary);
        }
    }
}