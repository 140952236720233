.overview {
    @extend .flex-row-center;
    background: url(../../../media/abstract-background/07-blue-shading.png) center center;
    padding: 40px 0px;

    .summary-section {
        @extend .flex-column-center;
        padding: 40px;
        @media (max-width: breakpoints(md)) {
            padding: 40px 20px !important;
        }

        .floating-box {
            background-color: rgba(255, 255, 255, 0.39);
            border-radius: 4px;
            height: max-content;
            // width: 95%;
            padding: 40px;
            @media (max-width: breakpoints(md)) {
                width: 100%;
            }

            .title {
                color: palette(primary, contrastText);
                font-style: italic;
                font-size: 30px !important;
                font-weight: 500 !important;
                margin-bottom: 20px;
                letter-spacing: 0.02em;
            }
            .summary {
                color: palette(primary, contrastText);
                font-weight: 400 !important;
                font-size: 18px !important;
                white-space: pre-line !important;
            }
            .more-btn {
                margin-top: 20px;
                color: white;
                border: 2px solid white;
            }
            .more-btn:hover {
                background-color: palette(secondary, main);
                border-color: palette(secondary, main);
            }
        }
    }
    .quote-section {
        @extend .flex-column-center;
        padding: 0px 80px;
        color: white;
        @media (max-width: breakpoints(md)) {
            padding: 40px 20px !important;
        }

        .quote-icon {
            width: 75px;
            margin-bottom: 20px;
        }
        .quote {
            margin-bottom: 20px;
        }
        .quote-source {
            text-align: right;
        }
    }
}