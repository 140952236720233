.contact {

    .color-bar {
        height: 5px;
        background-color: $primary-green;
    }
    .contact-root {
        padding: 50px 150px;
        height: max-content;
        background: url(../../media/abstract-background/07-blue-shading.png) no-repeat center center;

        @media (max-width: breakpoints(md)) {
            padding: 50px 80px !important;
        }

        @media (max-width: breakpoints(sm)) {
            padding: 50px !important;
        }

        &.thin-bar {
            padding: 20px 0px !important;
            .content {
                @media (max-width: breakpoints(md)) {
                    margin-bottom: 0px !important;
                }
            }
        }
        .section {
            height: 100%;

            .company-title {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            .title {
                @include typography(h6);
                font-weight: 700 !important;
                color: white;
                margin-right: 10px;
            }
            .content {
                @include typography(body2);
                color: white;
                white-space: pre-line !important;
                line-height: 1.5 !important;
                font-style: italic;

                @media (max-width: breakpoints(md)) {
                    margin-bottom: 20px;
                }

                .link {
                    color: white !important;
                }
                &.contact-only {
                    @extend .center-content;
                    flex-wrap: wrap;
                    
                    .contact-info {
                        margin: 0px 10px;
                    }
                }
            }
        }
    }   
}