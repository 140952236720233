@import './Theme';

//ALIGNMENT
.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    @extend .flex-row;
    align-content: center;
}

.flex-column-center {
    @extend .flex-column;
    justify-content: center;
}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

//COMMON STYLES
.root {
    padding-top: $header-height;
}

.root-w-footer {
    @extend .root;
    @extend .flex-column;
    min-height: 100vh;
}

.section-title {
    @include typography(h4);
    margin-bottom: 10px;
}

.divider {
    border-bottom-width: 2px !important;
    border-radius: 5px !important;
}

//ACCORDION STYLES
.basic-accordion {
    margin-bottom: 20px;
    background-color: white;
}

.basic-accordion-summary {
    border-radius: 2px;
    height: 25px !important; 
    min-height: 50px !important; //keep static height when expanded (override min-height set by MUI)
}

.basic-accordion-details {
    margin-top: 10px;
}

.scroll-anchor {
    margin-top: (-$header-height);
    padding-top: $header-height;
}