@import './Fonts.scss';

$header-height: 95px;

$primary-navy: #003399; // logo
$secondary-navy: #3E63B0;
$teriatiry-navy: #5671b7; // logo

$primary-blue: #60a7e8; // logo
$secondary-blue: #9dc4ed; // logo
$tertiary-blue: #e9f2f9;

$primary-green: #4db278;
$secondary-green: #72ce7c; // logo
$tertiary-green: #addfad; // logo

$theme: (
    custom: (
        header_height: $header-height,
        typography_fontFamily: ("Overpass", sans-serif),
    ),
    breakpoints: (
        xs: 0px,
        sm: 600px,
        md: 960px,
        lg: 1280px,
        xl: 1920px,
    ),
    palette: (
        primary: (
            light: $secondary-navy,
            main: $primary-navy,
            dark: darken($primary-navy, 12%),
            contrastText: #fff,
        ),
        secondary: (
            light: $tertiary-green,
            main: $primary-green,
            dark: darken($primary-green, 12%),
            contrastText: #fff,
        ),
        text: (
            primary: #000,
            secondary: #59616c,
            disabled: #979797,
        ),
        background: (
            default: #fff,
            paper: #F0F0F0,
            inset: #F0F2F5,
        ),
        grey: (
            50: #fafafa,
            100: #f5f5f5,
            200: #eeeeee,
            300: #e0e0e0,
            400: #bdbdbd,
            500: #9e9e9e,
            600: #757575,
            700: #616161,
            800: #424242,
            900: #212121,
            A50: #f0f0f0,
            A100: #d5d5d5,
            A200: #aaaaaa,
            A300: #969696,
            A400: #808080,
            A600: #686868,
            A700: #525252,
        ),
    ),
    shadows: (
        0: none,
        1: (2px 2px 5px 0px rgba(0,0,0,0.05)),
        2: (2px 2px 7px 0px rgba(0,0,0,0.075)),
        3: (2px 3px 7px 0px rgba(0,0,0,0.075), 0px 1px 10px 0px rgba(0,0,0,0.05)),
        4: (2px 4px 8px 0px rgba(0,0,0,0.075), 0px 1px 12px 0px rgba(0,0,0,0.075)),
        5: (2px 5px 8px 0px rgba(0,0,0,0.075), 0px 2px 14px 0px rgba(0,0,0,0.1)),
        6: (2px 6px 10px 0px rgba(0,0,0,0.1), 0px 2px 18px 0px rgba(0,0,0,0.1)),
        7: (2px 7px 10px 1px rgba(0,0,0,0.1), 0px 2px 16px 1px rgba(0,0,0,0.12)),
        8: (2px 8px 10px 1px rgba(0,0,0,0.1), 0px 3px 14px 2px rgba(0,0,0,0.12)),
        9: (2px 9px 12px 1px rgba(0,0,0,0.1), 0px 3px 16px 2px rgba(0,0,0,0.12)),
        10: (2px 10px 14px 1px rgba(0,0,0,0.12), 0px 4px 18px 3px rgba(0,0,0,0.12)),
        11: (2px 11px 15px 1px rgba(0,0,0,0.12), 0px 4px 20px 3px rgba(0,0,0,0.12)),
        12: (2px 12px 17px 2px rgba(0,0,0,0.12), 0px 5px 22px 4px rgba(0,0,0,0.12)),
        13: (2px 13px 19px 2px rgba(0,0,0,0.12), 0px 5px 24px 4px rgba(0,0,0,0.12)),
        14: (2px 14px 21px 2px rgba(0,0,0,0.12), 0px 5px 26px 4px rgba(0,0,0,0.12)),
        15: (2px 15px 22px 2px rgba(0,0,0,0.12), 0px 6px 28px 5px rgba(0,0,0,0.12)),
        16: (2px 16px 24px 2px rgba(0,0,0,0.12), 0px 6px 30px 5px rgba(0,0,0,0.12)),
        17: (2px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12)),
        18: (2px 18px 28px 2px rgba(0,0,0,0.14), 0px 7px 34px 6px rgba(0,0,0,0.12)),
        19: (2px 19px 29px 2px rgba(0,0,0,0.14), 0px 7px 36px 6px rgba(0,0,0,0.12)),
        20: (2px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12)),
        21: (2px 21px 33px 3px rgba(0,0,0,0.14), 0px 8px 40px 7px rgba(0,0,0,0.12)),
        22: (2px 22px 35px 3px rgba(0,0,0,0.14), 0px 8px 42px 7px rgba(0,0,0,0.12)),
        23: (2px 23px 36px 3px rgba(0,0,0,0.14), 0px 9px 44px 8px rgba(0,0,0,0.12)),
        24: (2px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)),
    ),
    typography: (
        h1: (
            fontFamily: 'Overpass',
            fontWeight: 700,
            fontSize: 3.75rem,
            lineHeight: 1.167,
            letterSpacing: 0em,
        ),
        h2: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 3.25rem,
            lineHeight: 1.2,
            letterSpacing: 0em,
        ),
        h3: (
            fontFamily: 'Overpass',
            fontWeight: 700,
            fontSize: 2.75rem,
            lineHeight: 1.167,
            letterSpacing: 0.02em,
        ),
        h4: (
            fontFamily: 'Overpass',
            fontWeight: 600,
            fontSize: 2.25rem,
            lineHeight: 1.235,
            letterSpacing: 0em,
        ),
        h5: (
            fontFamily: 'Overpass',
            fontWeight: 500,
            fontSize: 1.75rem,
            lineHeight: 1.334,
            letterSpacing: 0em,
        ),
        h6: (
            fontFamily: 'Overpass',
            fontWeight: 700,
            fontSize: 1.25rem,
            lineHeight: 1.6,
            letterSpacing: 0em,
        ),
        subtitle1: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 1.2rem,
            lineHeight: 1.75,
            letterSpacing: 0em,
        ),
        subtitle2: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 1.1rem,
            lineHeight: 1.57,
            letterSpacing: 0em,
        ),
        body1: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 0.95rem,
            lineHeight: 1.35,
            letterSpacing: 0em,
        ),
        body2: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 0.88rem,
            lineHeight: 1.35,
            letterSpacing: 0em,
        ),
        button: (
            fontFamily: 'Overpass',
            fontWeight: 600,
            fontSize: 0.95rem,
            lineHeight: 1.75,
            letterSpacing: 0.025em,
        ),
        caption: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 0.8rem,
            lineHeight: 1.66,
            letterSpacing: 0em,
        ),
        overline: (
            fontFamily: 'Overpass',
            fontWeight: 400,
            fontSize: 0.75rem,
            lineHeight: 2.66,
            letterSpacing: 0em,
        ),
    ),
    shape: (
        borderRadius: 3px,
    ),
);


// Functions and Mixins used by individual SASS files to access theme variables

@function breakpoints($size) {
    $breakpoints: map-get($theme, breakpoints);
    @if map-has-key($breakpoints, $size){
        @return map-get($breakpoints, $size);
    }
    @warn "`#{$size}` does not match the available breakpoint values (xs, sm, md, lg, xl)";
    @return null;
}

@function palette($type, $value) {
    $palette: map-get($theme, palette);
    @if map-has-key($palette, $type){
        $type: map-get($palette, $type);
            @if map-has-key($type, $value){
                @return map-get($type, $value);
            }
            @warn "`#{$value}` does not exist in palette type";
            @return null;        
    }
    @warn "`#{$color}` does not exist in palette";
    @return null;
}

@function shadows($depth) {
    $shadows: map-get($theme, shadows);
    @if map-has-key($shadows, $depth){
        @return map-get($shadows, $depth);
    }
    @warn "`#{$depth}` does not match the available shadow levels (0-24)";
    @return null;
}

@mixin typography($variant) {
    $typography: map-get($theme, typography);
    @if map-has-key($typography, $variant){
        font-family: map-get(map-get($typography, $variant), fontFamily) !important;
        font-weight: map-get(map-get($typography, $variant), fontWeight) !important;
        font-size: map-get(map-get($typography, $variant), fontSize) !important;
        line-height: map-get(map-get($typography, $variant), lineHeight) !important;
        letter-spacing: map-get(map-get($typography, $variant), letterSpacing) !important;
    } @else {
        @warn "`#{$variant}` does not match the available typography variants";
    }
}

@function shape($attribute) {
    $shape: map-get($theme, shape);
    @if map-has-key($shape, $attribute){
        @return map-get($shape, $attribute);
    }
    @warn "`#{$attribute}` is not an attribute of shape";
    @return null;
}