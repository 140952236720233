.endorsement {
    padding: 100px 40px;
    display: flex;
    justify-content: center;

    .edm-content-div {
        max-width: 950px;
    }
    .title {
        @extend .section-title;
        color: palette(primary, main);
    }
    .divider {
        @extend .divider;
        max-width: 250px;
        border-bottom-color: palette(primary, main);
        margin-bottom: 40px;
    }
    .edm-content {
        display: flex;
        @media (max-width: breakpoints(sm)) {
            flex-wrap: wrap;
            gap: 40px;
            justify-content: center;
        }
        
        .edm-image {
            display: inline-block;
            @media (min-width: breakpoints(sm)) {
                margin-right: 40px;
            }
        }
        .edm-text {
            @extend .flex-column-center;

            .img-caption {
                @extend .flex-row-center;
                margin-bottom: 40px;
                @media (min-width: 601px) {
                    gap: 10px;
                }
    
                .arrow-icon {
                    height: 50px;
                    transform: scale(-1,1);
                    @media (max-width: breakpoints(sm)) {
                        display: none;
                    }
                }
                .img-caption-text {
                    color: palette(text, secondary);
                }
            }
            .blurbs {
                @extend .flex-column;
                gap: 20px;
            }
        }
    }
}