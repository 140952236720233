.header {
    &.app-bar {
        background: white !important;
    }
    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        gap: 10px;
        @media (min-width: breakpoints(sm)) {
            padding: 0 5%;
            gap: 25px;
        }
    }
    .navigation {
        gap: 10px;
        display: flex;
        align-items: center;
        @media (min-width: breakpoints(sm)) {
            gap: 25px;
        }
    }
}
.header-link {
    text-decoration: none;
    padding: 2px;
    color: palette(text, primary) !important;

    :hover {
        color: palette(secondary, main) !important;
    }
    &.drawer {
        width: 100%;
    }
} 
.header-drawer {
    margin-top: $header-height !important;
    padding: 0px 20px;
}  
.logo-section {
    @extend .flex-column;
    .header-logo {
        display: flex;
        width: 200px;
    }
    .sc-tagline {
        display: flex;
        align-items: center;
        margin-left: 50px;
        white-space: nowrap;
        opacity: 60%;

        .sc-tagline-text {
            font-size: 10px;
            margin-right: 5px;
            color: black;
        }
    }
}