.carousel {
    background-color: #F0F2F5;
    padding: 80px 5%;
    @extend .flex-column;
    align-items: center;

    .csl-content {
        max-width: 100%;
    }
    .tagline {
        // color: palette(primary, main);
        font-weight: 700;
        margin-bottom: 40px;
        text-align: center;
    }
    .screenshots {

        .grid-item {
            @extend.flex-column;
            flex-wrap: wrap;
            padding: 50px;
            
            
            .box {
                box-shadow: shadows(2);
                background-color: white;
                border-radius: shape(borderRadius);
                width: 100%;
                height: 100%;
                padding: 50px;

                .title {
                    @include typography(h6);
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    color: palette(primary,light);
                }

                .txt {
                    @include typography(body1);
                    margin-bottom: 20px;
                }
                .sc {
                    border: 3px solid #3675B0;
                    width: 100%;
                    height: auto;
                }
            } 
        }
    }
}