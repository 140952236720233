.instructions {
    @extend .flex-column;
    @extend .center-content;
    gap: 30px;
    height: 100%;
    color: palette(primary,main) !important;

    @media (min-width: 900px) {
        padding-right: 50px;
        align-items: flex-end;
    }
    @media (max-width: 899px) {
        padding-bottom: 50px;
    }

    .title {    
        margin-bottom: 10px;
        font-size: 2.20rem;
        text-align: right;
        max-width: 600px;

        @media (max-width: breakpoints(md)) {
            text-align: center;
        }
    }
    .divider-section {
        @extend .flex-row;
        justify-content: flex-end;

        @media (max-width: breakpoints(md)) {
            justify-content: center;
        }

        .divider {
            border-bottom-color: palette(primary,main) !important;
            width: 100px !important;
            border-bottom-width: 3px !important;
            border-radius: 5px !important;
        }
    }
    .summary {
        @extend .center-content;
        gap: 20px;
        max-width: 500px;
        white-space: pre-line;

        @media (max-width: breakpoints(md)) {
            flex-direction: column;
        }

        .text{
            text-align: right;
            font-weight: 400 !important;

            @media (max-width: breakpoints(md)) {
                text-align: center;
            }
        }
        .arrow{
            width: 50px;

            @media (max-width: breakpoints(md)) {
                display: none;
            }
        }
    }
    .svg-graphic {
        max-width: 400px;
    }
}