.pricing {
    @extend .center-content;
    @extend .flex-column;
    background-color: palette(background, inset);
    box-shadow: inset 0px .5px 6px palette(grey,400);
    height: max-content;
    width: 100%;

    .text-box {
        @extend .flex-column;
        padding: 50px;
        width: 60%;
        align-items: center;
        gap: 30px;

        @media (max-width: breakpoints(md)) {
            width: 80%;
        }
        @media (max-width: breakpoints(sm)) {
            width: 100%;
        }
        
        .title {
            @include typography(h4);
            color: palette(primary, main);
            text-align: center;
            font-weight: 800 !important;
        }
        .content {
            color: palette(primary, main);
            text-align: center;
            font-style: italic;
            line-height: 1.8 !important;
            @media (min-width: breakpoints(lg)) {
                width: 75%;
            }   
        }
        .request-btn {
            background-color: palette(primary, light);
            align-self: center; 
        }
    }
}