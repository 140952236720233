.text-field {
    padding: 5px;
    
    .label {
        @include typography(body1);
        font-size: 12px !important;
    }
    .input {
        background-color: white !important;
        margin: 0;

        .MuiOutlinedInput-root {
            height: 40px !important;
        }

        .MuiOutlinedInput-input { //fix styling when autofilled 
            @include typography(body2);
            padding: 0px 10px  !important;
            height: 40px  !important;
            border-radius: 0;
        }

        &.multiline {
            .MuiOutlinedInput-root {
                height: 100% !important;
            }
        }
        
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }

        &.error {
            background-color: green;
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid red !important;
            }
        }
    }
    .error {
        @include typography(body2);
        font-size: 12px !important;       
        font-style: italic;
        color: red; 
    }
}