.request-demo {
    @extend .root-w-footer;

    .request-demo-contents {
        width: 100%;
        height: 100%;
        padding: 50px;
        flex: 1 0 auto;
    
        .content {
        
            .form-section {
                @extend .center-content;
            }
        }
    }
}